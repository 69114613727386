import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ManufacturersHero from "../../components/manufacturers-hero";
import ImageSide from "../../components/image-side";
import Badges from "../../components/badges";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import Container from "react-bootstrap/Container";
import MotForm from "../../components/mot-form";
import ImageSideContainer from "../../components/image-side-container";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const CampervanPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviewAuthor
				reviewDate
				reviewContent
			}
			schemaLogo: file(relativePath: { eq: "icon.png" }) {
				publicURL
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "campervan-bodyshop" }) {
				slug
				campervanBodyshopFields {
					campervanBodyshopBanner {
						campervanBodyshopBannerHeading
						campervanBodyshopBannerCta {
							title
							target
							url
						}
						campervanBodyshopBannerBackgroundImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					campervanBodyshopLeftContentRightImage {
						campervanBodyshopLeftContentHeading
						campervanBodyshopleftContentText
						campervanBodyshopLeftContentButton {
							title
							target
							url
						}
						campervanBodyshopRightImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					campervanBodyshopReviews {
						campervanBodyshopReviewsHeading
					}
					campervanBodyshopLeftImageRightContent {
						campervanBodyshopRightContentText
						campervanBodyshopRightContentHeading
						campervanBodyshopLeftImage {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					campervanBodyshopNormalContent {
						campervanBodyshopNormalContentText
						campervanBodyshopNormalContentHeading
					}
					campervanBodyshopLogoSlider {
						campervanBodyshopLogoSliderHeading
						campervanBodyshopLogoSliderNotice
						campervanBodyshopLogos {
							nodes {
								... on WpVehicleLogo {
									vehicleLogoFields {
										logoUrl
										logoImage {
											node {
												altText
												localFile {
													publicURL
													childImageSharp {
														gatsbyImageData(
															formats: [AUTO, WEBP]
															quality: 100
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
					campervanBodyshopForm {
						campervanBodyshopFormHeading
					}
					campervanBodyshopServicesCards {
						campervanBodyshopServicesCardsHeading
						campervanBodyshopServicesCards {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceUrl {
											target
											title
											url
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, campervanBodyshopFields, slug },
	} = data;

	const {
		campervanBodyshopBanner,
		campervanBodyshopLeftContentRightImage,
		campervanBodyshopReviews,
		campervanBodyshopLeftImageRightContent,
		campervanBodyshopNormalContent,
		campervanBodyshopLogoSlider,
		campervanBodyshopForm,
		campervanBodyshopServicesCards,
	} = campervanBodyshopFields;
	const siteUrl = data.site.siteMetadata.siteUrl;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
		mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		description: `${seoFields?.metaDescription}`,
		logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
		name: "ECF Car Care",
		url: "https://www.ecfcarcare.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
			image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
			name: "ECF Car Care",
			url: "https://www.ecfcarcare.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},

		review: {
			"@type": "Review",
			url: "https://www.ecfcarcare.co.uk/reviews",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "ECF Car Care",
				sameAs: "https://www.ecfcarcare.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Campervan Bodyshop",
				item: {
					url: `${siteUrl}/what-we-do/campervan-bodyshop`,
					id: `${siteUrl}/what-we-do/campervan-bodyshop`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{campervanBodyshopBanner && (
					<section>
						<ManufacturersHero
							title={campervanBodyshopBanner.campervanBodyshopBannerHeading}
							backgroundImage={
								campervanBodyshopBanner.campervanBodyshopBannerBackgroundImage
									.node?.localFile.childImageSharp.gatsbyImageData
							}
							cta={campervanBodyshopBanner.campervanBodyshopBannerCta}
						/>
					</section>
				)}

				{campervanBodyshopLeftContentRightImage &&
					!checkPropertiesForNull(campervanBodyshopLeftContentRightImage, [
						"campervanBodyshopLeftContentHeading",
						"campervanBodyshopRightImage",
					]) && (
						<div className="py-lg-8 py-5">
							<ImageSideContainer
								mPage
								image={
									campervanBodyshopLeftContentRightImage
										.campervanBodyshopRightImage.node?.localFile.childImageSharp
										.gatsbyImageData
								}
								imageAlt={
									campervanBodyshopLeftContentRightImage
										.campervanBodyshopRightImage.node?.altText
								}
								title={
									campervanBodyshopLeftContentRightImage.campervanBodyshopLeftContentHeading
								}
								order="last"
								imagePadding=" pb-5 pb-lg-0    "
								imageStyle="w-auto"
								text={
									campervanBodyshopLeftContentRightImage.campervanBodyshopleftContentText
								}
								ctaButton={
									campervanBodyshopLeftContentRightImage.campervanBodyshopLeftContentButton
								}
							/>
						</div>
					)}
				<section>
					<Reviews
						title={campervanBodyshopReviews?.campervanBodyshopReviewsHeading}
					/>
				</section>
				{campervanBodyshopLeftImageRightContent &&
					!checkPropertiesForNull(campervanBodyshopLeftImageRightContent, [
						"campervanBodyshopRightContentHeading",
						"campervanBodyshopRightContentText",
					]) && (
						<div className="pt-5 pt-lg-8 body-left-image-content">
							<ImageSide
								image={
									campervanBodyshopLeftImageRightContent
										.campervanBodyshopLeftImage.node?.localFile.childImageSharp
										.gatsbyImageData
								}
								imageAlt={
									campervanBodyshopLeftImageRightContent
										.campervanBodyshopLeftImage.node?.altText
								}
								title={
									campervanBodyshopLeftImageRightContent.campervanBodyshopRightContentHeading
								}
								order="start"
								imagePadding="px-lg-5  pb-5 pb-lg-0 px-3 "
								text={
									campervanBodyshopLeftImageRightContent.campervanBodyshopRightContentText
								}
							/>
						</div>
					)}

				{campervanBodyshopNormalContent &&
					!checkPropertiesForNull(campervanBodyshopNormalContent, [
						"campervanBodyshopNormalContentHeading",
					]) && (
						<section className="pt-5 bg-white">
							<Container>
								<Row>
									<Col>
										<h2 className="my-4 fs-1">
											{
												campervanBodyshopNormalContent.campervanBodyshopNormalContentHeading
											}
										</h2>

										<SafeHtmlParser
											htmlContent={
												campervanBodyshopNormalContent?.campervanBodyshopNormalContentText
											}
										/>
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{campervanBodyshopLogoSlider &&
					!checkPropertiesForNull(campervanBodyshopLogoSlider, [
						"campervanBodyshopLogoSliderHeading",
						"campervanBodyshopLogos",
					]) && (
						<Badges
							title={
								campervanBodyshopLogoSlider.campervanBodyshopLogoSliderHeading
							}
							text={
								campervanBodyshopLogoSlider.campervanBodyshopLogoSliderNotice
							}
							logos={campervanBodyshopLogoSlider.campervanBodyshopLogos?.nodes}
						/>
					)}
				<section id="book" className="pb-5  pt-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h2 className="text-center pb-4">
									{" "}
									{campervanBodyshopForm?.campervanBodyshopFormHeading ||
										"BOOK YOUR APPOINTMENT"}
								</h2>
								<div
									style={{ background: "#F9F9F9", borderRadius: "18px" }}
									className="p-md-5 p-4"
								>
									<MotForm page="Campervan Bodyshop" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* {campervanBodyshopServicesCards &&
          !checkPropertiesForNull(campervanBodyshopServicesCards, [
            "campervanBodyshopServicesCardsHeading",
            "campervanBodyshopServicesCards",
          ]) && (
            <OurServicesSection
              title={
                campervanBodyshopServicesCards.campervanBodyshopServicesCardsHeading
              }
              services={
                campervanBodyshopServicesCards.campervanBodyshopServicesCards
                  ?.nodes
              }
            />
          )} */}
			</Layout>
		</>
	);
};

export default CampervanPage;
