import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { SafeHtmlParser } from "./safeHtmlParser";

const ManufacturersHero = ({ title, backgroundImage, cta }) => {
  const pluginImage = getImage(backgroundImage);
  return (
    <BgImage Tag="section" className="position-relative" image={pluginImage}>
      <div
        style={{ zIndex: 1, opacity: 0.6, backgroundColor: "black" }}
        className="position-absolute end-0 w-lg-70 w-100 h-100 m-hero-div"
      ></div>
      <Container
        style={{ zIndex: 2 }}
        className="py-lg-10 py-md-5 pb-5 position-relative"
      >
        <Row className=" pt-3 px-3 justify-content-end">
          <Col lg={7} xl={6} className="pl-0  text-center ">
            <h1
              className="pt-3 mb-3 text-white display-4 text-uppercase"
              id="services-hero-title"
            >
              <SafeHtmlParser htmlContent={title} />
            </h1>

            {cta && cta.url && (
              <a className="fs-4 pt-5 inter-bold white-link" href={cta.url}>
                {cta.title || "Book an appointment"}
              </a>
            )}
          </Col>
        </Row>
      </Container>
    </BgImage>
  );
};

export default ManufacturersHero;
